var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"modal-files-service"}},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"1200px","scrollable":""},on:{"keydown":_vm.executeModalFilesService,"click:outside":_vm.executeModalFilesService},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[(_vm.currentService)?_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" Documentos del Servicio: "),_c('b',[_vm._v(_vm._s(_vm.currentService.name))])])]):_vm._e(),_c('hr'),_c('v-card-text',[_c('v-container',{},[(_vm.currentService)?_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tabsFiles),callback:function ($$v) {_vm.tabsFiles=$$v},expression:"tabsFiles"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"value":"quotes"}},[_vm._v(" Cotizaciones ")]),(_vm.typeRequestService == _vm.REQUEST_SERVICE_MAVERICK && false)?_c('v-tab',{attrs:{"value":"maverick_purchase"}},[_vm._v(" Carta de Aprobación Maverick ")]):_vm._e(),_c('v-tab',{attrs:{"value":"other"}},[_vm._v(" Otros Documentos ")])],1):_vm._e(),(_vm.currentService)?_c('section',{staticClass:"mt-3"},[(_vm.documentFilesFromCategory.length)?_c('v-simple-table',{key:_vm.keyDocumentsTable,staticClass:"my-12",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.documentFilesFromCategory.length > 1 
                      && _vm.tabsFiles == _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes 
                      && (_vm.userCreateRequestService || _vm.activeManagmetFiles|| _vm.activeEditPriceAndSupplierFiles || _vm.permissionToUserChangeSelected) )?_c('th',{staticClass:"text-left"},[_vm._v(" Cotización Seleccionada ")]):_vm._e(),_c('th',{staticClass:"text-left"},[_vm._v(" Nombre ")]),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes && !_vm.userCreateRequestService
                      )?_c('th',{staticClass:"text-left"},[_vm._v(" Tasa de Cambio Utilizada ")]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes && _vm.userCreateRequestService
                      )?_c('th',{staticClass:"text-left"},[_vm._v(" Tipo de Cambio ")]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('th',{staticClass:"text-left"},[_vm._v(" Precio ")]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('th',{staticClass:"text-left"},[_vm._v(" Precio en Pesos ")]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('th',{staticClass:"text-center"},[_vm._v(" Proveedor ")]):_vm._e(),((
                        _vm.userCreateRequestService || 
                        _vm.activeManagmetFiles
                      ))?_c('th',{staticClass:"text-center"},[_vm._v(" Acción ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.documentFilesFromCategory),function(document){return _c('tr',{key:document.id},[(_vm.documentFilesFromCategory.length > 1 && _vm.tabsFiles == _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes && (_vm.userCreateRequestService || _vm.activeManagmetFiles || _vm.activeEditPriceAndSupplierFiles || _vm.permissionToUserChangeSelected))?_c('td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-checkbox',{attrs:{"disabled":_vm.currentRequestStatusIsPendingApproval,"input-value":document.selected},on:{"change":function($event){return _vm.selectDocument(document)}}})],1)]):_vm._e(),_c('td',[(!_vm.userCreateRequestService)?_c('a',{attrs:{"href":document.document_path,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center my-1"},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"red"}},[_vm._v(" mdi-file-pdf ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"my-2"},'p',attrs,false),on),[_vm._v(" "+_vm._s(document.document_name)+" ")])]}}],null,true)},[_c('span',[_vm._v("Descargar archivo")])])],1)]):_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v(" mdi-file-pdf ")]),_c('p',{staticClass:"my-2 font-weight-bold"},[_vm._v(" "+_vm._s(document.file.name)+" ")])],1)]),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('td',[_c('p',{staticClass:"my-0 mt-1",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(document.exchange_rate)+" ")])]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('td',[(_vm.userCreateRequestService || _vm.currentDocumentEdit == document.id)?_c('section',{staticClass:"text-left d-flex align-items-center"},[_c('v-select',{staticClass:"font-size-12 mr-3 mb-1",staticStyle:{"max-width":"60px"},attrs:{"items":_vm.REQUEST_SERVICE_CONSTANTS.coin_types,"item-text":"text","item-value":"value"},model:{value:(document.coin),callback:function ($$v) {_vm.$set(document, "coin", $$v)},expression:"document.coin"}}),_c('small',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.REQUEST_SERVICE_CONSTANTS.getCurrencySymbol(document.coin))+" ")]),(_vm.userCreateRequestService)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(document.price),expression:"document.price"}],staticClass:"form-control-quantity",attrs:{"type":"text","hide-details":""},domProps:{"value":(document.price)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(document, "price", $event.target.value)},function($event){return _vm.formatPrice(document)}]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDocumentPrice),expression:"currentDocumentPrice"}],staticClass:"form-control-quantity",attrs:{"type":"text","hide-details":""},domProps:{"value":(_vm.currentDocumentPrice)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.currentDocumentPrice=$event.target.value},function($event){return _vm.formatCurrentDocumentPrice()}]}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.currentDocumentEdit == document.id)?_c('v-btn',_vm._g(_vm._b({staticClass:"pointer mx-1",attrs:{"icon":"","disabled":_vm.loadingDocument,"x-small":""},on:{"click":function($event){return _vm.updatePriceQuotation(document)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-send ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" Confirmar modificación de precio ")])])],1):_c('p',{staticClass:"my-0 mt-1",staticStyle:{"font-size":"12px"}},[_c('section',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(((document.price ? document.price.toLocaleString('en-US', { maximumFractionDigits: 2 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',') : 0) + " " + (document.coin ? document.coin : "CLP")))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.activeEditPriceAndSupplierFiles && _vm.currentDocumentEdit != document.id)?_c('v-btn',_vm._g(_vm._b({staticClass:"pointer mx-1",attrs:{"icon":"","disabled":_vm.loadingDocument,"x-small":"","color":"red"},on:{"click":function($event){return _vm.setCurrentDocumentEdit(document)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(document.price ? "Modificar precio" : "Cotizar Precio"))])])],1)])]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('td',[(_vm.userCreateRequestService || _vm.currentDocumentEdit == document.id)?_c('p',{staticClass:"my-0 mt-1",staticStyle:{"font-size":"12px"}},[_vm._v(" --- ")]):_c('p',{staticClass:"my-0 mt-1",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(document.amount ? document.amount : "-")+" CLP ")])]):_vm._e(),(
                        _vm.tabsFiles ==
                          _vm.REQUEST_SERVICE_CONSTANTS.document_category_quotes
                      )?_c('td',[(document.supplier_name || document.supplier_rut || document.supplier)?_c('span',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(document.supplier_name ? document.supplier_name : document.supplier_rut ? document.supplier_rut : document.supplier)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.activeEditPriceAndSupplierFiles)?_c('v-btn',_vm._g(_vm._b({staticClass:"pointer",attrs:{"icon":"","x-small":"","disabled":_vm.loadingDocument,"color":"red"},on:{"click":function($event){return _vm.openModalSearchSupplier(document)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modificar Proveedor")])])],1):(_vm.userCreateRequestService || (_vm.uploadFiles && _vm.activeEditPriceAndSupplierFiles))?_c('v-btn',{attrs:{"small":"","color":"primery","disabled":_vm.loadingDocument},on:{"click":function($event){return _vm.openModalSearchSupplier(document)}}},[_vm._v(" Agregar Proveedor ")]):_c('span',[_vm._v(" - ")])],1):_vm._e(),((
                      _vm.userCreateRequestService || 
                      _vm.activeManagmetFiles
                    ) && _vm.permissionToDeleteFile(document))?_c('td',[_c('v-btn',{attrs:{"icon":"","color":"red darken-2","small":""},on:{"click":function($event){_vm.userCreateRequestService ? _vm.deleteFile(document) : _vm.deleteUploadFile(document)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1):_vm._e()])}),0)]},proxy:true}],null,false,3238083072)}):_c('p',{staticClass:"text-center my-5 pt-5"},[_vm._v(" Sin Archivos Cargados ")]),(_vm.loadingDocument)?_c('section',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"indeterminate":"","color":"primary"}}),_c('span',[_vm._v(" Actualizando Documento ")])],1):_vm._e()],1):_vm._e(),(_vm.filesRequired.length && !_vm.userCreateRequestService && !_vm.currentRequestStatusIsRejected)?_c('section',{staticClass:"mx-2"},[_c('h5',[_vm._v("Documentos Necesarios")]),_c('ul',{staticClass:"mt-3 px-0"},_vm._l((_vm.filesRequired),function(file){return _c('li',{key:file.id,staticClass:"d-flex align-items-center my-1"},[_c('span',{staticClass:"text-left font-weight-bold"},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" - ")]),_c('span',{staticClass:"mx-2",class:{
                    'text-success': file.status,
                    'text-danger': !file.status
                  }},[_vm._v(" Tienes "+_vm._s(file.count)+" "+_vm._s(file.count > 1 || file.count == 0 ? 'Archivos' : 'Archivo')+" ")]),_c('span',{staticStyle:{"font-size":"1.1rem"}},[_vm._v(" "+_vm._s(file.status ? '✅' : '❌')+" ")])])}),0)]):_vm._e(),(((_vm.userCreateRequestService || _vm.activeManagmetFiles) || (
              _vm.uploadFiles &&
                ((!_vm.requestingUserModule &&
                  _vm.currentServiceStatusIsQuotationOrInReview) ||
                  (_vm.requestingUserModule && _vm.currentServiceStatusIsQuotation))
            )))?_c('section',{staticClass:"mt-8"},[_c('v-file-input',{attrs:{"accept":"application/pdf","multiple":"","counter":"","show-size":"","chips":"","deletable-chips":"","placeholder":"Puedes Cargar Aquí los Documentos del Servicio","value":_vm.form.files},on:{"change":_vm.updateFiles,"click:clear":function($event){_vm.form.files = []}}}),_c('v-btn',{staticClass:"mt-5 float-right",attrs:{"color":"primary","shaped":"","small":"","disabled":!_vm.form.files.length},on:{"click":function($event){_vm.userCreateRequestService ? _vm.updateFilesService() : _vm.updateFilesUploadService()}}},[_vm._v(" Cargar Archivos "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" mdi-send ")])],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeModalFilesService}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1)],1),_c('table-add-supplier',{ref:"tableAddSupplier",attrs:{"modalAction":_vm.dialogAddSupplier,"closeModalTableAddSupplier":_vm.closeModalTableAddSupplier,"currentDocument":_vm.currentDocument,"typeRequestService":_vm.typeRequestService},on:{"addSupplierSelected":_vm.addSupplierSelected,"openModalCreateSupplier":_vm.openModalCreateSupplier,"openModalSetOnlyRutSupplier":_vm.openModalSetOnlyRutSupplier}}),_c('search-supplier',{ref:"searchSupplier",attrs:{"modalAction":_vm.dialogSearchSupplier,"closeModalSearchSupplier":_vm.closeModalSearchSupplier,"onlyRut":_vm.onlyRut},on:{"emitSearch":_vm.openModalAddSupplier,"addSupplier":_vm.addSupplierSelected}}),_c('modal-create-supplier',{attrs:{"modalAction":_vm.dialogCreateSupplier,"closeModalCreateSupplier":_vm.closeModalCreateSupplier,"showOnlyCompleteSupplier":true},on:{"emitNameSupplier":_vm.addSupplierSelected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
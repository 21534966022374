<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalComments"
      @click:outside="executeCloseModalComments"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5
            v-if="currentService || currentRequest"
            v-html="
              `Comentarios de ${
                currentRequest ? 'Solicitud Correlativo:' : 'Servicio'
              } <b>${getTitleFromCurrentServiceOrRequest()}</b> `
            "
          ></h5>
        </div>
        <hr />
        <v-card-text class="pb-0">
          <v-container class="pb-0">
            <v-row
              class="mx-0 mb-8 scroll-vertical"
              style="max-height: 400px"
              v-if="haveComments"
            >
              <v-col
                cols="12"
                md="12"
                sm="12"
                class="py-0 my-0"
                style="border-radius: 24px"
                v-for="(item, index) in getCurrentComments"
                :key="index"
              >
                <v-row justify="start" align-content="center">
                  <v-col cols="12" md="12" sm="12" class="mb-8">
                    <div class="mr-2" style="border-radius: 15px">
                      <div
                        class="mb-2 ml-1 d-flex align-items-center justify-content-start"
                      >
                        <v-btn
                          class="mr-1 ml-1 elevation-0"
                          x-small
                          fab
                          color="blue darken-3"
                        >
                          <v-icon color="white">
                            mdi-account
                          </v-icon>
                        </v-btn>
                        <h5 class="d-block mb-0 ml-1">
                          {{ `${item.created_by}` }}
                        </h5>
                        <v-icon
                          color="red"
                          class="mx-2 pointer"
                          v-if="user.email == item.created_by"
                          @click="deleteComment(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </div>
                    </div>
                    <p class="d-block hp-p1-body ml-3 mb-1 text-black">
                      {{ item.comment }}
                    </p>
                    <small class="ml-3">
                      {{
                        moment(item.updated_at).format("DD/MM/YYYY HH:mm [hrs]")
                      }}
                    </small>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <p v-else class="text-center mb-8 text-black">
              Sin comentarios
            </p>
            <v-form
              class=""
              @submit.prevent="sendNewComment"
              v-if="addComments"
            >
              <v-textarea
                :disabled="loading"
                v-model="$v.form.comment.$model"
                outlined
                cols="8"
                rows="4"
                placeholder="Ingresa aqui el comentario.."
                :error="$v.form.comment.$invalid && submitUpload"
                :error-messages="
                  $v.form.comment.$invalid && submitUpload
                    ? 'Este campo es requerido'
                    : null
                "
              ></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalComments"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
          <v-btn
            v-if="addComments"
            @click="sendNewComment"
            :disabled="!form.comment"
            color="primary"
            class="float-right text-white"
          >
            <small>
              Enviar Comentario
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
import moment from "moment";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalComments: {
      type: Function,
      default: () => {},
    },
    currentService: {
      default: null,
    },
    requestId: {
      default: null,
    },
    addComments: {
      type: Boolean,
      default: true,
    },
    currentRequest: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      loading: false,
      form: {
        comment: "",
      },
      submitUpload: false,
    };
  },
  validations: {
    form: {
      comment: { required },
    },
  },
  methods: {
    ...mapActions({
      createComment: "requestService/createCommentService",
      createCommentRequest: "requestService/createCommentRequest",
      sendDeleteCommentForm: "requestService/deleteCommentService",
      sendDeleteCommentRequestForm: "requestService/deleteCommentRequest",
    }),
    getTitleFromCurrentServiceOrRequest() {
      return this.currentService
        ? this.currentService.name
        : this.currentRequest.order;
    },
    async sendNewComment() {
      this.submitUpload = true;
      let resp = null;
      if (!this.$v.form.$invalid) {
        this.loading = true;
        if (this.currentRequest) {
          resp = await this.createCommentRequest({
            request_id: this.currentRequest.id,
            comment: this.form.comment,
            user: this.user.email,
          });
        } else {
          resp = await this.createComment({
            service_id: this.currentService.id,
            comment: this.form.comment,
            user: this.user.email,
          });
        }
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: "Comentario agregado correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("commentAdded");
          this.closeModalComments(true);
          this.resetForm();
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    async deleteComment(comment) {
      let resp = null;
      swal2
        .fire({
          icon: "warning",
          title: `¿Estás seguro de eliminar este comentario?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: `Eliminar`,
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              request_id: this.currentRequest
                ? this.currentRequest.id
                : this.requestId,
              service_id: this.currentService.id,
              comment_id: comment.id,
              user: this.user.email,
            };
            if (this.currentRequest) {
              resp = await this.sendDeleteCommentRequestForm(payload);
            } else {
              resp = await this.sendDeleteCommentForm(payload);
            }
            if (!resp || !resp.status || resp.status == 500) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 400) {
              this.$swal({
                icon: "error",
                title: "Error",
                text: resp.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp.status == 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: `Comentario eliminado correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.loading = false;
          }
        });
    },
    executeCloseModalComments($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalComments($event);
      }
    },
    resetForm() {
      this.form = {
        comment: "",
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    haveComments() {
      return (
        (this.currentService && this.currentService.comments.length) ||
        (this.currentRequest && this.currentRequest.comments.length)
      );
    },
    getCurrentComments() {
      return this.currentService
        ? this.currentService.comments
        : this.currentRequest.comments;
    },
  },
};
</script>

<style></style>

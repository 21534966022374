<template>
  <v-dialog
    v-model="modalAction"
    max-width="600px"
    scrollable
    @keydown="executeCloseModalSearchSupplier"
    @click:outside="executeCloseModalSearchSupplier"
  >
    <v-card>
      <v-card-text class="px-5 pt-5">
        <h5 class="mb-3">
          Ingresa
          {{ onlyRut || filterSelected ? "el RUT/Código" : "Razón Social" }} del
          Proveedor
        </h5>
        <v-row align-content="center" justify="start" v-if="!onlyRut">
          <v-col cols="12" md="6" sm="12">
            <v-checkbox
              :value="true"
              :input-value="filterSelected"
              @change="filterSelected = !filterSelected"
              class="mt-4"
              hide-details
            >
              <template v-slot:label>
                <h6 class="mt-2 mx-1 mb-0">
                  Filtrar por RUT/Código
                </h6>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-checkbox
              :value="false"
              :input-value="!filterSelected"
              @change="filterSelected = !filterSelected"
              class="mt-4"
              hide-details
            >
              <template v-slot:label>
                <h6 class="mt-2 mx-1 mb-0">
                  Filtrar por Razón Social
                </h6>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div class="d-flex align-items-center justify-content-center">
          <v-text-field
            v-if="onlyRut || filterSelected"
            ref="rutInput"
            v-model="rut"
            placeholder="RUT/Código del Proveedor"
            required
            hide-details
            outlined
            class="pt-8 mr-5"
            au
            @keypress.enter="onlyRut ? validateRutAndEmit() : validateAndEmitSearch()"
          >
          </v-text-field>
          <v-text-field
            v-if="!onlyRut && !filterSelected"
            ref="rutInput"
            v-model="business_name"
            placeholder="Razón Social del Proveedor"
            required
            hide-details
            outlined
            class="pt-8"
            @keypress.enter="onlyRut ? validateRutAndEmit() : validateAndEmitSearch()"
          >
          </v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" outlined @click="executeCloseModalSearchSupplier"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          @click="onlyRut ? validateRutAndEmit() : validateAndEmitSearch()"
        >
          {{ onlyRut ? "Ingresar" : "Buscar" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { formatToChileanRut, validateRut } from "@/helpers/rut";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalSearchSupplier: {
      type: Function,
      default: () => {},
    },
    onlyRut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterSelected: true,
      rut: null,
      business_name: null,
      rutInput: null,
    };
  },
  watch: {
    modalAction: function(newVal) {
      if (newVal) {
        setTimeout(() => {
          requestAnimationFrame(() => {
            this.$refs.rutInput.focus();
          });
        }, 100);
      }
    },
  },
  methods: {
    executeCloseModalSearchSupplier($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.rut = null;
        this.closeModalSearchSupplier($event);
      }
    },
    formatRut() {
      this.rut = formatToChileanRut(this.rut);
    },
    validateAndEmitSearch() {
      if (!this.rut && !this.business_name) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Debe ingresar el RUT/Código o la Razón Social del Proveedor",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.$emit("emitSearch", {
          search: this.filterSelected ? this.rut : this.business_name,
          filterFrom: this.filterSelected ? "rut" : "business_name",
        });
        this.rut = null;
        this.business_name = null;
        this.closeModalSearchSupplier();
      }
    },
    validateRutAndEmit() {
      if (!this.rut) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "El RUT/Código es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.$emit("addSupplier", {
        rut: this.rut,
      });
      this.rut = null;
      this.closeModalSearchSupplier();
    },
  },
};
</script>

<style></style>

<template>
  <section id="modal-files-service">
    <loading-flux :value="loading"></loading-flux>
    <v-dialog v-model="modalAction" max-width="1200px" scrollable @keydown="executeModalFilesService"
      @click:outside="executeModalFilesService">
      <v-card>
        <div class="p-4 pb-0" v-if="currentService">
          <h5>
            Documentos del Servicio: <b>{{ currentService.name }}</b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-tabs v-if="currentService" v-model="tabsFiles" fixed-tabs>
              <v-tabs-slider></v-tabs-slider>
              <v-tab class="" value="quotes">
                Cotizaciones
              </v-tab>
              <!-- maverick_purchase is hidden by ticket AD-383 -->
              <v-tab v-if="typeRequestService == REQUEST_SERVICE_MAVERICK && false" value="maverick_purchase">
                Carta de Aprobación Maverick
              </v-tab>

              <v-tab value="other">
                Otros Documentos
              </v-tab>
            </v-tabs>
            <section v-if="currentService" class="mt-3">
              <v-simple-table :key="keyDocumentsTable" class="my-12" v-if="documentFilesFromCategory.length">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" v-if="documentFilesFromCategory.length > 1 
                        && tabsFiles == REQUEST_SERVICE_CONSTANTS.document_category_quotes 
                        && (userCreateRequestService || activeManagmetFiles|| activeEditPriceAndSupplierFiles || permissionToUserChangeSelected) ">
                        Cotización Seleccionada
                      </th>
                      <th class="text-left">
                        Nombre
                      </th>
                      <th class="text-left" v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes && !userCreateRequestService
                        ">
                        Tasa de Cambio Utilizada
                      </th>
                      <th class="text-left" v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes && userCreateRequestService
                        ">
                        Tipo de Cambio
                      </th>
                      <th class="text-left" v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        Precio
                      </th>
                      <th class="text-left" v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        Precio en Pesos
                      </th>
                      <th class="text-center" v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        Proveedor
                      </th>
                      <th class="text-center" v-if="(
                          userCreateRequestService || 
                          activeManagmetFiles
                        )">
                        Acción
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="document in documentFilesFromCategory" :key="document.id">
                      <td
                        v-if="documentFilesFromCategory.length > 1 && tabsFiles == REQUEST_SERVICE_CONSTANTS.document_category_quotes && (userCreateRequestService || activeManagmetFiles || activeEditPriceAndSupplierFiles || permissionToUserChangeSelected)">
                        <div class="d-flex justify-content-center">
                          <v-checkbox :disabled="currentRequestStatusIsPendingApproval" :input-value="document.selected"
                            @change="selectDocument(document)">
                          </v-checkbox>
                        </div>
                      </td>
                      <td>
                        <a v-if="!userCreateRequestService" :href="document.document_path" target="_blank">
                          <div class="d-flex align-items-center my-1">
                            <v-icon color="red" class="mx-2">
                              mdi-file-pdf
                            </v-icon>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <p v-bind="attrs" v-on="on" class="my-2">
                                  {{ document.document_name }}
                                </p>
                              </template>
                              <span>Descargar archivo</span>
                            </v-tooltip>
                          </div>
                        </a>
                        <div v-else class="d-flex align-items-center justify-content-start">
                          <v-icon color="red" class="mr-2">
                            mdi-file-pdf
                          </v-icon>
                          <p class="my-2 font-weight-bold">
                            {{ document.file.name }}
                          </p>
                        </div>
                      </td>
                      <td v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        <p class="my-0 mt-1" style="font-size: 12px">
                          {{ document.exchange_rate }}
                        </p>
                      </td>
                      <td v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        <!-- <section class="text-left"
                          v-if="userCreateRequestService || currentDocumentEdit == document.id">
                          
                        </section> -->
                        <section class="text-left d-flex align-items-center"
                          v-if="userCreateRequestService || currentDocumentEdit == document.id">
                          <v-select v-model="document.coin" :items="REQUEST_SERVICE_CONSTANTS.coin_types"
                            item-text="text" item-value="value" style="max-width: 60px"
                            class="font-size-12 mr-3 mb-1" />

                          <small class="mr-1">
                            {{ REQUEST_SERVICE_CONSTANTS.getCurrencySymbol(document.coin) }}
                          </small>
                          <input v-if="userCreateRequestService" class="form-control-quantity" type="text"
                            v-model="document.price" @input="formatPrice(document)" hide-details />
                          <input v-else class="form-control-quantity" type="text" v-model="currentDocumentPrice"
                            @input="formatCurrentDocumentPrice()" hide-details />

                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="updatePriceQuotation(document)" v-if="currentDocumentEdit == document.id"
                                v-on="on" v-bind="attrs" icon :disabled="loadingDocument" x-small class="pointer mx-1">
                                <v-icon color="primary" class="">
                                  mdi-send
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Confirmar modificación de precio
                            </span>
                          </v-tooltip>
                        </section>
                        <p v-else class="my-0 mt-1" style="font-size: 12px">
                        <section class="d-flex align-items-center">
                          {{
                            `${document.price ?
                              document.price.toLocaleString('en-US', { maximumFractionDigits: 2 }).replace(/\./g,
                                '#').replace(/,/g, '.').replace(/#/g, ',')
                              : 0}
                          ${document.coin ? document.coin : "CLP"}`
                          }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="setCurrentDocumentEdit(document)"
                                v-if="activeEditPriceAndSupplierFiles && currentDocumentEdit != document.id"
                                v-bind="attrs"
                                icon
                                :disabled="loadingDocument"
                                x-small
                                color="red"
                                class="pointer mx-1"
                                v-on="on"
                              >
                                <v-icon color="orange" class="">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{
                              document.price
                                ? "Modificar precio"
                                : "Cotizar Precio"
                            }}</span>
                          </v-tooltip>
                        </section>
                        </p>
                      </td>

                      <td v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        <p v-if="userCreateRequestService || currentDocumentEdit == document.id" class="my-0 mt-1" style="font-size: 12px">
                          ---
                        </p>
                        <p v-else class="my-0 mt-1" style="font-size: 12px">
                          {{ document.amount ? document.amount : "-" }} CLP
                        </p>
                      </td>
                      
                      <td v-if="
                          tabsFiles ==
                            REQUEST_SERVICE_CONSTANTS.document_category_quotes
                        ">
                        <span v-if="document.supplier_name || document.supplier_rut || document.supplier" class="d-flex align-items-center">
                          {{ document.supplier_name ? document.supplier_name : document.supplier_rut ?
                          document.supplier_rut : document.supplier }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="openModalSearchSupplier(document)" v-if="activeEditPriceAndSupplierFiles"
                                v-on="on" v-bind="attrs" icon x-small :disabled="loadingDocument" color="red"
                                class="pointer">
                                <v-icon color="orange" class="">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Modificar Proveedor</span>
                          </v-tooltip>
                        </span>
                        <v-btn v-else-if="userCreateRequestService || (uploadFiles && activeEditPriceAndSupplierFiles)"
                          small color="primery" class="" :disabled="loadingDocument"
                          @click="openModalSearchSupplier(document)">
                          Agregar Proveedor
                        </v-btn>
                        <span v-else>
                          -
                        </span>
                      </td>
                      <td v-if="(
                        userCreateRequestService || 
                        activeManagmetFiles
                      ) && permissionToDeleteFile(document)">
                        <v-btn icon color="red darken-2" small
                          @click="userCreateRequestService ? deleteFile(document) : deleteUploadFile(document)">
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p class="text-center my-5 pt-5" v-else>
                Sin Archivos Cargados
              </p>
              <section class="d-flex align-items-center justify-content-center" v-if="loadingDocument">
                <v-progress-circular class="mx-2" indeterminate color="primary"></v-progress-circular>
                <span>
                  Actualizando Documento
                </span>
              </section>
            </section>
            <section class="mx-2"
              v-if="filesRequired.length && !userCreateRequestService && !currentRequestStatusIsRejected">
              <h5>Documentos Necesarios</h5>
              <ul class="mt-3 px-0">
                <li class="d-flex align-items-center my-1" v-for="file in filesRequired" :key="file.id">
                  <span class="text-left font-weight-bold">
                    {{file.name}}
                  </span>
                  <span class="ml-2">
                    -
                  </span>
                  <span class="mx-2" :class="{
                      'text-success': file.status,
                      'text-danger': !file.status
                    }">
                    Tienes {{file.count}} {{file.count > 1 || file.count == 0 ? 'Archivos' : 'Archivo'}}
                  </span>
                  <span style="font-size: 1.1rem">
                    {{file.status ? '✅' : '❌'}}
                  </span>
                </li>
              </ul>
            </section>

            <section class="mt-8" v-if="((userCreateRequestService || activeManagmetFiles) || (
                uploadFiles &&
                  ((!requestingUserModule &&
                    currentServiceStatusIsQuotationOrInReview) ||
                    (requestingUserModule && currentServiceStatusIsQuotation))
              ))">
              <v-file-input accept="application/pdf" multiple counter show-size chips deletable-chips
                placeholder="Puedes Cargar Aquí los Documentos del Servicio" :value="form.files" @change="updateFiles"
                @click:clear="form.files = []"></v-file-input>
              <v-btn class="mt-5 float-right" color="primary" shaped small :disabled="!form.files.length"
                @click="userCreateRequestService ? updateFilesService() : updateFilesUploadService()">
                Cargar Archivos
                <v-icon class="ml-2" small>
                  mdi-send
                </v-icon>
              </v-btn>
            </section>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="executeModalFilesService">
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <table-add-supplier ref="tableAddSupplier" :modalAction="dialogAddSupplier"
      :closeModalTableAddSupplier="closeModalTableAddSupplier" :currentDocument="currentDocument"
      @addSupplierSelected="addSupplierSelected" @openModalCreateSupplier="openModalCreateSupplier"
      @openModalSetOnlyRutSupplier="openModalSetOnlyRutSupplier" :typeRequestService="typeRequestService">
    </table-add-supplier>
    <search-supplier ref="searchSupplier" :modalAction="dialogSearchSupplier"
      :closeModalSearchSupplier="closeModalSearchSupplier" :onlyRut="onlyRut" @emitSearch="openModalAddSupplier"
      @addSupplier="addSupplierSelected">
    </search-supplier>
    <modal-create-supplier :modalAction="dialogCreateSupplier" :closeModalCreateSupplier="closeModalCreateSupplier"
      :showOnlyCompleteSupplier="true" @emitNameSupplier="addSupplierSelected">
    </modal-create-supplier>
  </section>
</template>

<script>
  import { mapActions } from "vuex";
  import swal2 from "sweetalert2";
import {
  preventCloseIfNotKeyEventEsc,
  renameAndReturnNewFile,
} from "@/helpers/common";
import REQUEST_SERVICE_CONSTANTS from "@/constants/requestService";
import { REQUEST_SERVICE_MAVERICK } from "@/constants/requestMaterial";
import { authUsuarioComputed } from "@/state/helpers";
import TableAddSupplier from './TableAddSupplier.vue';
import SearchSupplier from '@/components/commons/SearchSupplier.vue';
import ModalCreateSupplier from '../../supplying/formSuppliers/components/ModalCreateSupplier.vue';

export default {
  components: { TableAddSupplier, SearchSupplier, ModalCreateSupplier },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalFilesService: {
      type: Function,
      default: () => {},
    },
    currentService: {
      default: null,
    },
    requestId: {
      default: null,
    },
    currentRequest: {
      default: null,
    },
    requestingUserModule: {
      type: Boolean,
      default: false,
    },
    uploadFiles: {
      type: Boolean,
      default: true,
    },
    typeRequestService: {
      default: null,
    },
    userCreateRequestService: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keyDocumentsTable: new Date().getTime(),
      loading: false,
      loadingDocument: false,
      tabsFiles: "quotes",
      REQUEST_SERVICE_CONSTANTS,
      REQUEST_SERVICE_MAVERICK,
      form: {
        files: [],
      },
      // Add Supplier
      onlyRut: false,
      dialogAddSupplier: false,
      dialogCreateSupplier: false,
      dialogSearchSupplier: false,
      currentDocument: null,

      // Edit Document
      currentDocumentEdit: null,
      currentDocumentPrice: null,
    };
  },
  methods: {
    ...mapActions({
      sendDeleteFile: "requestService/deleteFileService",
      sendUpdateFilesService: "requestService/updateService",
      sendUpdateService: "requestService/updateService",
    }),
    setCurrentDocumentEdit(document) {
      this.currentDocumentEdit = document.id;
      this.currentDocumentPrice = document.price ? document.price.toLocaleString('en-US', { maximumFractionDigits: 2 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',') : 0;
    },
    async selectDocument(document) {
      if(this.userCreateRequestService){
        this.currentService.documents.forEach((file) => {
          if (file.id == document.id) {
            file.selected = !file.selected;
          }else {
            file.selected = false;
          }
        });
        this.keyDocumentsTable++;
      }else {
      await this.updateService({
          [document.document_name]: {
            action: 'update',
            comment: "Modificación de selección",
            selected: !document.selected,
            supplier: document.supplier,
            type: document.document_type,
            unit_price: document.price,
            coin: document.coin ? document.coin : "CLP",
          }
        });
        this.keyDocumentsTable++;
      }
    },
    executeModalFilesService($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.form.files = [];
        this.closeModalFilesService($event);
      }
    },
    formatCurrency(value) {
      if(value) {
        let sanitizedValue = String(value).replace(/[^\d,]/g, '');
        let number = parseFloat(sanitizedValue.replace(/,/g, '.'));

        if (isNaN(number) || sanitizedValue.charAt(sanitizedValue.length - 1) === ',') {
          return value;
        }
        if (isNaN(number) || sanitizedValue.charAt(sanitizedValue.length - 1) === ',') {
          return value;
        }

        return number.toLocaleString('en-US', { maximumFractionDigits: 2 }).replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',');
      } else {
       return value;
      }
    },
    formatPrice(document) {
      document.price = this.formatCurrency(document.price);
    },
    formatCurrentDocumentPrice() {
      this.currentDocumentPrice = this.formatCurrency(this.currentDocumentPrice);
    },
    updateFiles(newFiles) {
      if (
        newFiles.length &&
        newFiles.find((file) => file.type !== "application/pdf")
      ) {
        this.form.files = [];
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: "Solo se permiten archivos de formato PDF",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      if (newFiles.length && newFiles !== this.form.files) {
        this.form.files = [...this.form.files, ...newFiles];
      }
    },
    updateFilesService() {
      if (this.form.files !== this.currentService.documents) {
        let fileNameDuplicated = false;
        this.form.files.forEach((file) => {
          if (
            this.currentService.documents.find(
              (document) => document.file.name === file.name
            )
          ) {
            fileNameDuplicated = true;
          }
        });
        if (fileNameDuplicated) {
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: "Ya existe un archivo con el mismo nombre",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
        }
        this.currentService.documents = [
          ...this.currentService.documents,
          ...this.form.files.map((item, index) => ({
            file: item,
            id: new Date().getTime() + index,
            single_supplier_letter: false,
            category: this.tabsFiles,
            coin: "CLP",
          })),
        ];
      }
      this.form.files = [];
    },
    async updateFilesUploadService() {
      let self = this;
      self.loading = true;
      let filesToUpload = [];
      let payload = {
        service_id: self.currentService ? self.currentService.id : undefined,
        user: self.user.email,
        unit_price: self.currentService.unit_price,
        quantity: self.currentService.quantity,
        documents: self.form.files.reduce((acc, cur, index) => {
          let filename = `${
            self.currentService.sap_code
          }_${new Date().getTime() + index}_${cur.name}`;
          let fileWithNewName = renameAndReturnNewFile(cur, filename);
          acc[filename] = {
            action: 'add',
            comment: "Archivo cargado",
            selected: false,
            supplier: null,
            type: REQUEST_SERVICE_CONSTANTS.getTypeDocumentFromFile(
              cur.category ? cur.category : {category: self.tabsFiles},
              self.typeRequestService == REQUEST_SERVICE_MAVERICK
            ),
            unit_price: 0,
            coin: 'CLP',
          };
          filesToUpload.push(fileWithNewName);
          return acc;
        }, {}),
      };
      payload.files = filesToUpload;
      const resp = await self.sendUpdateFilesService(payload);
      if (resp?.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Documentos agregados correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        self.form.files = [];
        self.$emit("filesUploaded", self.currentService);
    }
    if (!resp?.status || resp?.status === 400) {
        self.loading = false;
        return swal2.fire({
          icon: "error",
          title: "Error",
          text: resp
            ? resp.data.detail
            : "Ha ocurrido un error de servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 10000,
          timerProgressBar: true,
        });
    }
    self.loading = false;
  },
  async updatePriceQuotation(document) {
    let self = this;
    if (!self.currentDocumentPrice || 
        self.currentDocumentPrice.replaceAll('.', '').replaceAll(",", ".") == 0 || 
        isNaN(Number(this.currentDocumentPrice.replaceAll(".", "").replaceAll(",", ".")))
      ) {
      return self.$swal.fire({
        icon: "error",
        title: "Error",
        text: "El precio debe ser mayor que 0",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 5000,
        timerProgressBar: true,
      });    
    }
    this.$swal
      .fire({
        icon: "info",
        title: `Confirma el precio para la cotización: <b>${
          document.document_name ? document.document_name : "-"
        }</b> de $${this.currentDocumentPrice}?`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "primary",
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.loadingDocument = true;
          const payload = {
            
            [document.document_name]: {
              action: 'update',
              comment: "Modificación de precio",
              selected: document.selected,
              supplier: document.supplier,
              type: document.document_type,
              unit_price: Number(self.currentDocumentPrice.replaceAll(".", "").replaceAll(",", ".")),
              coin: document.coin ? document.coin : "CLP",
            }
          }
          await this.updateService(payload);
        }
      });
  },
  async updateService(documents){
    this.loading = true;
    let payload = {
      service_id: this.currentService.id,
      quantity: this.currentService.quantity,
      documents: documents,
      user: this.user.email,
    };
    const resp = await this.sendUpdateService(payload);
    if (!resp || !resp.status || resp.status == 500) {
      this.$swal({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error en el servidor, intenta nuevamente",
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 3000,
        timerProgressBar: true,
      });
      return this.loading = false;
    }
    if (resp.status == 400) {
      this.$swal({
        icon: "error",
        title: "Error",
        text: resp.data.detail,
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 3000,
        timerProgressBar: true,
      });
    }
    if (resp.status == 200) {
      this.$swal({
        icon: "success",
        title: "Excelente",
        text: `Cotización actualizada correctamente`,
        position: "top-end",
        showConfirmButton: false,
        toast: true,
        timer: 3000,
        timerProgressBar: true,
      });
      this.currentDocumentEdit = null;
      this.currentDocumentPrice = null;
      this.$emit("updateDocumentsCurrentService");
    }
    this.loading = false;
  },
  async deleteFile({ id, file }) {
      swal2
        .fire({
          icon: "info",
          title: `¿Estás seguro de eliminar este archivo: ${file.name}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.currentService.documents = this.currentService.documents.filter(
              (document) => document.id !== id
            );
          }
        });
    },
    async deleteUploadFile(file) {
      swal2
        .fire({
          icon: "info",
          title: `¿Estás seguro de eliminar este documento: ${file.document_name}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            let payload = {
              request_id: this.requestId,
              document_id: file.id,
              user: this.user.email,
              service_id: this.currentService.id,
            };
            const resp = await this.sendDeleteFile(payload);
            if (!resp || !resp.status || resp.status == 500) {
              swal2.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intenta nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (resp && resp.status == 200) {
              swal2.fire({
                icon: "success",
                title: "Excelente",
                text: `Archivo del servicio eliminado correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$emit("updateDocumentsCurrentService");
            }
            this.loading = false;
          }
        });
    },
    // Add Supplier
    async addSupplierSelected(payload) {
      let self = this
      if(this.onlyRut){
        this.onlyRut = false;
      }
      if(this.userCreateRequestService){
        this.currentService.documents.forEach((file) => {
          if (file.id == self.currentDocument.id) {
            file.supplier_name = payload.name ? payload.name : null;
            file.supplier_rut = payload.rut;
          }
        });
      }else {
        this.loadingDocument = true;
        await this.updateService({
            [this.currentDocument.document_name]: {
              action: 'update',
              comment: "Modificación de proveedor",
              selected: this.currentDocument.selected,
              supplier: payload.rut,
              type: this.currentDocument.document_type,
              unit_price: this.currentDocument.price ?
                 this.currentDocument.price : this.currentDocument.unit_price 
                 ? this.currentDocument.unit_price : 0,
              coin: this.currentDocument.coin ? this.currentDocument.coin : "CLP",
            }
          });
      }
      this.keyDocumentsTable++;
    },
    openModalAddSupplier({search, filterFrom}) {
      if(filterFrom == "rut"){
        this.$refs.tableAddSupplier.filters.rut = search;
        this.$refs.tableAddSupplier.showFilterName = false;
      }else{
        this.$refs.tableAddSupplier.filters.name = search;
        this.$refs.tableAddSupplier.showFilterRut = false;
      }
      this.$refs.tableAddSupplier.getSuppliers(
        1,
        this.typeRequestService == REQUEST_SERVICE_MAVERICK ? true : false
      );
      this.dialogAddSupplier = true;
    },
    closeModalTableAddSupplier() {
      this.dialogAddSupplier = false;
    },
    openModalSearchSupplier(document) {
      this.currentDocument = null;
      this.currentDocument = document;
      this.dialogSearchSupplier = true;
    },
    closeModalSearchSupplier() {
      this.dialogSearchSupplier = false;
    },
    openModalCreateSupplier(){
      this.dialogCreateSupplier = true;
    },
    closeModalCreateSupplier() {
      this.dialogCreateSupplier = false;
    },
    openModalSetOnlyRutSupplier(rut){
      if(rut){
        return this.addSupplierSelected({name: rut, rut: rut});
      }
      this.onlyRut = true;
      this.$refs.searchSupplier.rut = rut;
      this.dialogSearchSupplier = true;
    },
    // Validar si el usuario puede eliminar los archivos
    permissionToDeleteFile(document) {
      if(this.userCreateRequestService){
        return true
      }
      if(document.created_by == this.user.email){
        return true
      }
      if(document.created_by != this.currentRequest.created_by && this.currentRequest.created_by != this.user.email){
        return true
      }
      return false
    },

  },
  computed: {
    ...authUsuarioComputed,
    currentServiceStatusIsQuotation() {
      return (
        this.currentService &&
        this.currentService.status ===
          REQUEST_SERVICE_CONSTANTS.service_statuses.in_quotation
      );
    },
    currentServiceStatusIsQuotationOrInReview() {
      return (
        this.currentService &&
        (this.currentServiceStatusIsQuotation ||
          this.currentService.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.in_review)
      );
    },
    currentServiceStatusIsQuotationInValidation(){
      return (
        this.currentService &&
          this.currentService.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.quotation_in_validation
      );
    },
    currentRequestStatusIsPendingValidation(){
      return (
        this.currentRequest &&
          this.currentRequest.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.pending_validation
      );
    },
    currentRequestStatusIsUserValidationVariant(){
      return (
        this.currentRequest &&
          this.currentRequest.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.user_validation_variant
      );
    },
    currentRequestStatusIsPendingApproval(){
      return (
        this.currentRequest &&
          this.currentRequest.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.pending
      );
    },
    currentRequestStatusIsRejected(){
      return (
        this.currentRequest &&
          (this.currentRequest.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.rejected || this.currentRequest.status === REQUEST_SERVICE_CONSTANTS.service_statuses.rejected_variant)
      );
    },
    currentRequestStatusIsPurchaseValidation(){
      return (
        this.currentRequest &&
          this.currentRequest.status ===
            REQUEST_SERVICE_CONSTANTS.service_statuses.purchase_validation
      );
    },
    activeManagmetFiles() {
      // Caso Maverick y esta en validacion de usuario
      if ((this.typeRequestService == REQUEST_SERVICE_MAVERICK &&
        (this.currentRequest && this.currentRequest.status === REQUEST_SERVICE_CONSTANTS.service_statuses.user_validation) &&
        this.currentServiceStatusIsQuotationInValidation && this.requestingUserModule)) {
        return true
      } 
      // Caso de Cotización por parte de compras
      if(
        this.typeRequestService != REQUEST_SERVICE_MAVERICK &&
        this.currentServiceStatusIsQuotationOrInReview &&
        !this.requestingUserModule
      ){
        return true
      }

      // Caso de Validación por parte de compras
      if(
      this.typeRequestService != REQUEST_SERVICE_MAVERICK &&
      this.currentRequestStatusIsPurchaseValidation &&
      !this.requestingUserModule
      ){
        return true
      }

      return false
    },
    activeEditPriceAndSupplierFiles() {
      // Caso Maverick y esta en validacion de usuario
      if ((this.typeRequestService == REQUEST_SERVICE_MAVERICK &&
        (this.currentRequest && this.currentRequest.status === REQUEST_SERVICE_CONSTANTS.service_statuses.user_validation) &&
        this.currentServiceStatusIsQuotationInValidation && this.requestingUserModule)) {
        return true
      } 
      // Caso de Cotización por parte de compras
      // TODO refactor this condition, unify with the activeManagmetFiles computed
      if (
        this.typeRequestService != REQUEST_SERVICE_MAVERICK &&
        ((this.currentServiceStatusIsQuotationOrInReview && !this.requestingUserModule) || (this.currentServiceStatusIsQuotation && this.requestingUserModule))
      ) {
        return true
      }
      
      // Caso de Validación por parte de compras
      if(
        this.typeRequestService != REQUEST_SERVICE_MAVERICK &&
        this.currentRequestStatusIsPurchaseValidation &&
        !this.requestingUserModule
      ){
        return true
      }

      return false
    },
    // Validar si el usuario puede cambiar el estado de selección de cotizaciones
    permissionToUserChangeSelected(){
      return (this.requestingUserModule &&
        this.currentRequestStatusIsUserValidationVariant) 
       || this.currentRequestStatusIsPendingApproval
      
    },
    documentFilesFromCategory() {
      if (this.currentService) {
        if(this.userCreateRequestService){
          return this.currentService.documents.filter(
            (item) => item.category === this.tabsFiles
          );
        }else {
          return this.currentService.documents
          .map((document) => {
            document.category = REQUEST_SERVICE_CONSTANTS.getDocumentCategory(document.document_type, this.typeRequestService == REQUEST_SERVICE_MAVERICK);
            return document;
          })
          .filter((document) => document.category == this.tabsFiles)
          .sort((a, b) => a.document_name.localeCompare(b.document_name));
        }
      }
      return [];
    },
    // Archivos requeridos para la solicutud de servicio
    filesRequired() {
      // Caso de Maverick
      if(this.typeRequestService == REQUEST_SERVICE_MAVERICK && this.currentService){
        return [
          {
            name: "1 Cotización",
            count: this.currentService.documents.filter((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text).length,
            status: this.currentService.documents.find((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text)
          },
          // {
          //   name: "1 Carta de Aprobación Maverick",
          //   count: this.currentService.documents.filter((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_maverick_purchase_text).length,
          //   status: this.currentService.documents.find((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_maverick_purchase_text)
          // },
        ]
      }
      // Si hay al menos una cotización con un precio mayor o igual a la cantidad máxima
      if(this.currentService && this.currentService.documents.find((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text && Number(document.amount.replaceAll(".", "").replaceAll(",", ".")) >= REQUEST_SERVICE_CONSTANTS.service_amount_to_request_more_quotes_files)){
        return [
          {
            name: "3 Cotizaciones",
            count: this.currentService.documents.filter((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text).length,
            status: this.currentService.documents.filter((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text).length >= 3
          }
        ]
      }
      // Caso por defecto
      if(this.currentService){
        return [
          {
            name: "1 Cotización",
            count: this.currentService.documents.filter((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text).length,
            status: this.currentService.documents.find((document) => document.document_type == REQUEST_SERVICE_CONSTANTS.document_category_quotes_text)
          }
        ]
      }
      return []
    }
  },
};
</script>

<style>
.form-control-quantity {
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 0;
  max-width: 120px;
}
</style>

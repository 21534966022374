<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1300px"
      @keydown="executeCloseTableAddSupplier"
      @click:outside="executeCloseTableAddSupplier"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Buscar Proveedor</h5>
        </div>
        <hr />
        <v-card-text>
          <div class="card-body p-2 pb-0">
            <v-row align="start">
              <v-col cols="12" md="4" sm="12" class="py-5" v-if="showFilterRut">
                <v-text-field
                  v-model="filters.rut"
                  @input="getSuppliers(1)"
                  append-icon="mdi-magnify"
                  label="Buscar por RUT/Código"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="12"
                class="py-5"
                v-if="showFilterName"
              >
                <v-text-field
                  v-model="filters.name"
                  @input="getSuppliers(1)"
                  append-icon="mdi-magnify"
                  label="Buscar por Razón Social"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="12"
                v-if="typeRequestService !== REQUEST_SERVICE_MAVERICK"
              >
                <v-btn
                  :disabled="loadingSuppliers"
                  @click="openModalSetOnlyRutSupplier"
                  color="primery"
                  class="float-right mt-6"
                >
                  Agregar RUT/Código del Proveedor
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="12"
                v-if="typeRequestService === REQUEST_SERVICE_MAVERICK"
              >
                <v-btn
                  :disabled="loadingSuppliers"
                  @click="openModalCreateSupplier"
                  color="primery"
                  class="float-right mt-6"
                >
                  Agregar Proveedor
                </v-btn>
              </v-col>
            </v-row>
            <section class="w-75 my-5">
              <h5 class="font-weight-bold">
                <b class="text-primary"> IMPORTANTE </b>
              </h5>
              <p class="text-primary mt-1">
                <b>Selecciona un Proveedor</b>. Si el Proveedor no se encuentra
                en el listado, pulsa el botón
                <span v-if="typeRequestService !== REQUEST_SERVICE_MAVERICK">
                  <b>Agregar RUT/Código del Proveedor</b> para ingresar el
                  RUT/Código y posteriormente sea validado por el equipo de
                  compras.
                </span>
                <span v-else>
                  <b>Agregar Proveedor</b> para ingresar el proveedor en el
                  formulario de proveedores y posteriormente sea validado por el
                  equipo de compras.
                </span>
              </p>
            </section>
            <v-data-table
              :headers="headers"
              :items="wrappedSuppliers.items"
              item-key="code"
              class="elevation-1 mt-4"
              :items-per-page="10"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingSuppliers"
              loading-text="Cargando... Espere Por favor"
              @page-count="pageCount = $event"
            >
              <template v-slot:item="{ item, isExpanded }">
                <tr
                  @click="addSupplierSelected(item)"
                  :key="item.id"
                  class="pointer"
                >
                  <td class="text-left">
                    <p class="my-0 text-left" style="font-size: 12px">
                      {{ item.code }}
                    </p>
                  </td>
                  <td class="text-left">
                    <p class="my-0 text-left" style="font-size: 12px">
                      {{ item.name }}
                    </p>
                  </td>
                  <td class="text-left">
                    <p class="my-0 text-left" style="font-size: 12px">
                      {{ item.email }}
                    </p>
                  </td>
                  <td class="text-left">
                    <p class="my-0 text-left" style="font-size: 12px">
                      {{ item.origin }}
                    </p>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <div class="d-flex justify-content-end pt-2 pb-1">
              <v-pagination
                v-model="filters.page"
                class="v-application"
                :length="
                  wrappedSuppliers.number_of_pages
                    ? wrappedSuppliers.number_of_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
            <div class="v-application">
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                outlined
                class="mr-3 mt-4"
                small
                @click="executeCloseTableAddSupplier"
                >Cancelar</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { REQUEST_SERVICE_MAVERICK } from "@/constants/requestMaterial";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalTableAddSupplier: {
      type: Function,
      default: () => {},
    },
    currentDocument: {
      default: null,
    },
    typeRequestService: {
      default: null,
    },
  },
  data() {
    return {
      REQUEST_SERVICE_MAVERICK,
      loadingSuppliers: false,
      debounce: null,
      debounceTime: 500,
      selected: [],
      showFilterRut: true,
      showFilterName: true,
      filters: {
        page: 1,
        items: 10,
        rut: "",
        name: "",
      },
      headers: [
        {
          text: "RUT/Código",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Razón Social",
          width: "50%",
          value: "name",
          sortable: true,
        },
        {
          text: "Correo Electrónico",
          width: "30%",
          value: "email",
          sortable: true,
        },
        {
          text: "Origen",
          width: "10%",
          value: "origin",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getAllSuppliers: "requestService/getSuppliers",
      clearAllSuppliers: "requestService/clearAllSuppliers",
      getSuppliersForm: "supplying/getSuppliers",
    }),
    async getSuppliers(page = 1, openModalAddSupplier = false) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        self.loadingSuppliers = true;
        self.filters.page = page;
        await self.getAllSuppliers({
          page: self.filters.page,
          items: 10,
          filter: self.showFilterRut
            ? self.filters.rut
              ? self.filters.rut.replaceAll(".", "")
              : undefined
            : self.filters.name
            ? self.filters.name
            : undefined,
        });
        if (!self.wrappedSuppliers.items.length) {
          await self.getSuppliersForm({
            payload: {
              page: 1,
              filter: self.showFilterRut
                ? self.filters.rut
                  ? self.filters.rut
                  : undefined
                : self.filters.name
                ? self.filters.name
                : undefined,
            },
            setInSuppliersExternal: true,
          });
          self.loadingSuppliers = false;
        }
        self.loadingSuppliers = false;
      }, self.debounceTime);
    },
    addSupplierSelected(item) {
      this.$emit("addSupplierSelected", {
        name: item.name,
        rut: item.code,
        currentDocument: this.currentDocument,
      });
      this.resetFilter();
      this.closeModalTableAddSupplier();
    },
    executeCloseTableAddSupplier($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.showFilterRut = true;
        this.showFilterName = true;
        this.closeModalTableAddSupplier($event);
      }
    },
    resetFilter() {
      this.filters.rut = "";
      this.filters.name = "";
      this.clearAllSuppliers();
    },
    openModalSetOnlyRutSupplier() {
      this.$emit("openModalSetOnlyRutSupplier", this.filters.rut);
      this.showFilterRut = true;
      this.showFilterName = true;
      this.closeModalTableAddSupplier();
    },
    openModalCreateSupplier() {
      this.closeModalTableAddSupplier();
      this.$emit("openModalCreateSupplier");
    },
  },
  computed: {
    ...mapGetters({
      wrappedSuppliers: "requestService/allSupliers",
    }),
  },
  watch: {
    "filters.page": function (page) {
      this.getSuppliers(page);
    },
  },
};
</script>

<style></style>
